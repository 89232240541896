<template>
  <div>
    <div v-if="this.isAuthenticated">
      <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
        <template v-slot:title>
          {{ title }}
        </template>
        <template v-slot:body>
          <h6>{{ $t("COMPLAINT_DATA_APPLICATION.DATA_REQUEST_DETAIL") }}</h6>
          <br />
          <b-form class="form" @submit.stop.prevent="">
            <div class="row">
              <div class="col-md-8">
                <input
                  type="file"
                  ref="file1"
                  @change="selectedFile(1)"
                  id="file1"
                  style="display: none"
                />
                <button
                  class="btn"
                  style="margin-right: 40px; background-color: #0c02c4"
                  @click="$router.push('complaint-data-application-eksternal')"
                >
                  <img src="media/image/paper.png" style="width: 50px" />
                  <span style="color: white;">
                    Isi Formulir Permohonan Eksternal
                    <br />
                    (instansi/Perorangan)
                    {{ filename1 }}
                  </span>
                </button>

                <button
                  class="btn"
                  style="background-color: #f5313e"
                  @click="$router.push('complaint-data-application-internal')"
                >
                  <img src="media/image/paper.png" style="width: 50px" />
                  <span style="color: white">
                    Isi Formulir Permohonan Internal
                    <br />
                    (Pegawai Komnas HAM)
                    {{ filename2 }}
                  </span>
                </button>
              </div>
              <div class="col-md-4"></div>
            </div>

            <br /><br />
            <span>
              <p>
                <b>{{ $t("COMPLAINT_DATA_APPLICATION.TERMS1") }}</b> <br />
                <b>{{ $t("COMPLAINT_DATA_APPLICATION.TERMS2") }}</b> <br />
                <b>{{ $t("COMPLAINT_DATA_APPLICATION.TERMS3") }}</b> <br />
              </p>
            </span>

          </b-form>

          <br /><br />

          <b-modal ref="my-modal" size="sm">
            <div class="d-block text-center">
              <h3>
                {{ $t("COMPLAINT_DATA_APPLICATION.SUBMIT_MESSAGE") }}
              </h3>
            </div>
            <template #modal-footer="{}">
              <b-button size="lg" variant="danger" @click="hideModal()">
                TUTUP
              </b-button>
            </template>
          </b-modal>

          <b-modal ref="my-modal2" size="sm">
            <div class="d-block text-center">
              <h3>
                {{ $t("COMPLAINT_DATA_APPLICATION.SUBMIT_ERROR") }}
              </h3>
            </div>
            <template #modal-footer="{ close }">
              <b-button size="lg" variant="danger" @click="close()">
                TUTUP
              </b-button>
            </template>
          </b-modal>

          <!-- BEGIN LIST -->
          <h3>{{ $t("COMPLAINT_DATA_APPLICATION.HISTORY") }}</h3>
          <b-row>
            <b-col sm="3">
              <b-form-input id="search" v-model="searchText"></b-form-input>
            </b-col>
            <b-col sm="1">
              <a class="btn btn-icon btn-light-success" @click="search()">
                <i class="flaticon2-search-1"></i>
              </a>
            </b-col>
          </b-row>
          <br />
          <b-table
            hover
            bordered
            no-border-collapse
            responsive
            head-row-variant="dark"
            :items.sync="items"
            :fields="fields"
            :busy.sync="isBusy"
            head-variant="dark"
            @sort-changed="onSort"
          >
            <template #cell(Nomor_Permohonan)="data">
              <span v-if="data.item.download_type_id != 1">{{
                data.item.request_data_id
              }}</span>
            </template>
            <template #cell(Dokumen)="data">
              <a
                :href="createDownloadUrl(data.item.download_url)"
                class="btn btn-light-primary mr-3"
                target="_blank"
                v-if="data.item.download_status_id != 2"
              >
                <i class="flaticon2-download-1"></i>
                Download
              </a>
            </template>
            <template #cell(File_Surat)="data">
              <a
                :href="createDownloadUrl(data.item.filesurat)"
                class="btn btn-light-primary mr-3"
                target="_blank"
                v-if="
                  data.item.download_status_id == 1 &&
                  data.item.download_data_type_id == 2 &&
                  data.item.download_name != 'Dashboard Download'
                "
              >
                <i class="flaticon2-download-1"></i>
                Download
              </a>
            </template>
          </b-table>
          <b-pagination
            v-on:input="changePage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
          ></b-pagination>
        </template>
      </KTCard>
    </div>
    <div v-if="!this.isAuthenticated">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div
            class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
            style="
              background-position: calc(100% + 0.5rem) bottom;
              background-size: 35% auto;
              background-image: url(media/svg/humans/custom-10.svg);
            "
          >
            <h2 class="text-dark pb-5 font-weight-bolder">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}
            </h2>
            <p class="text-dark-50 pb-5 font-size-h5">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW") }}<br />
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_SECOND_PARAGRAPH") }}
            </p>
            <a
              @click="registerClicked"
              class="btn btn-danger font-weight-bold py-2 px-6"
              >{{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  GET_PROPOSAL_TYPE,
  SET_PROPOSAL_TYPE,
  SET_PROPOSAL_TYPE_SELECTED,
  GET_DOWNLOAD_HISTORY,
  SET_DOWNLOAD_HISTORY,
  SET_DOWNLOAD_HISTORY_FILTER,
  SET_DOWNLOAD_HISTORY_LOADING,
  SET_DOWNLOAD_HISTORY_PAGE,
  SET_DOWNLOAD_HISTORY_SORT,
} from "@/core/services/store/complaintdata.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTCard from "@/view/content/Card.vue";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import * as axios from "axios";

export default {
  mixins: [validationMixin],
  name: "complaint-data-application",
  data() {
    return {
      selectFile: false,
      checked: false,
      proposalTypes: [],
      selectedProposalType: null,
      form: {
        proposaltype: 0,
        description: "",
        requestletterfile: null,
        appletterfile: null,
      },
      fileData1: "",
      fileData2: "",
      filename1: "",
      filename2: "",
      title: this.$t("MENU.COMPLAINT_RECEIPT_DATA_FORM"),
      items: [],
      currentPage: 1,
      totalRows: 1,
      perPage: 1,
      isBusy: true,
      searchText: "",
      fields: [
        {
          key: "download_name",
          sortable: true,
          label: "Jenis Informasi",
        },
        {
          key: "download_type_text",
          sortable: true,
          label: "Cara Akses",
        },
        {
          key: "download_data_type_text",
          sortable: true,
          label: "Jenis Data",
        },
        {
          key: "download_date",
          sortable: true,
          label: "Tanggal Akses",
        },
        {
          key: "download_status_text",
          sortable: true,
          label: "Status",
        },
        "Nomor_Permohonan",
        "Dokumen",
        "File_Surat",
      ],
    };
  },
  validations: {
    form: {
      proposaltype: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch(GET_PROPOSAL_TYPE);
    this.bindingData();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.COMPLAINT_DATA"),
        route: "complaint-receipt-data",
      },
      { title: this.$t("MENU.COMPLAINT_RECEIPT_DATA_FORM") },
    ]);
    this.getItems();
  },
  methods: {
    bindingData() {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === SET_PROPOSAL_TYPE) {
          this.proposalTypes = this.getProposalType;
        }
      });
    },
    proposalTypeSelect(val) {
      this.$store.dispatch(SET_PROPOSAL_TYPE_SELECTED, val);
      this.selectedProposalType = val;
    },
    checkbox() {
      this.checked = true;
    },
    selectedFile(nbr) {
      switch (nbr) {
        case 1:
          this.fileData1 = this.$refs.file1.files[0];
          // this.selectFile = this.validate_fileupload(this.fileData1);
          // if (this.selectFile == false) {
          //   Swal.fire("Format File", "File format not supported", "error");
          //   return;
          // }
          if (this.fileData1.size > 10485760) {
            Swal.fire("File size limit", "File too big (> 10MB)", "error");
            return;
          } else {
            this.filename1 = this.fileData1.name;
          }
          break;
        case 2:
          this.fileData2 = this.$refs.file2.files[0];

          if (this.fileData2.size > 10485760) {
            Swal.fire("File size limit", "File too big (> 10MB)", "error");
            return;
          } else {
            this.filename2 = this.fileData2.name;
          }
          break;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cancelForm() {
      this.$router.push("dashboard");
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmitData() {
      let formData = new FormData();

      formData.set("request_purpose", this.$v.form.proposaltype.$model);
      formData.set("request_description", this.$v.form.description.$model);
      formData.append("request_proposal_file", this.fileData1);
      formData.append("request_letter", this.fileData2);
      axios
        .post("/api/smartmap/request_data", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + JwtService.getToken(),
          },
        })
        .then(() => {
          this.$refs["my-modal"].show();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$refs["my-modal2"].show();
        });

      this.$v.form.$touch();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      window.location.reload();
      return;
    },
    search() {
      this.$store.dispatch(SET_DOWNLOAD_HISTORY_FILTER, this.searchText);
    },
    getItems() {
      this.$store.dispatch(GET_DOWNLOAD_HISTORY);
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === SET_DOWNLOAD_HISTORY) {
          this.items = this.getDownloadHistory;
          this.currentPage = this.getDownloadHistoryCurrentPage;
          this.totalRows = this.getDownloadHistoryTotalRow;
          this.perPage = this.getDownloadHistoryPerPage;
        }

        if (mutation.type === SET_DOWNLOAD_HISTORY_LOADING) {
          this.isBusy = this.getDownloadHistoryLoadingStatus;
        }
      });
    },
    createDownloadUrl(fileName) {
      return fileName;
    },

    onSort(ctx) {
      let a = {};
      a[ctx.sortBy] = ctx.sortDesc === true ? "desc" : "asc";
      this.$store.dispatch(SET_DOWNLOAD_HISTORY_SORT, a);
    },
    changePage(ctx) {
      this.$store.dispatch(SET_DOWNLOAD_HISTORY_PAGE, ctx);
    },
    registerClicked() {
      this.$router.push({ name: "register" });
    },
    validate_fileupload(fileName) {
      var allowed_extensions = new Array(
        "jpg",
        "png",
        "gif",
        "jpeg",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "pdf"
      );
      var file_extension = fileName.split(".").pop().toLowerCase();

      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true; // valid file extension
        }
      }
      return false;
    },
  },
  components: {
    KTCard,
  },
  computed: mapGetters([
    "isAuthenticated",
    "getProposalType",
    "getDownloadHistory",
    "getDownloadHistoryLoadingStatus",
    "getDownloadHistoryCurrentPage",
    "getDownloadHistoryTotalPage",
    "getDownloadHistoryPerPage",
    "getDownloadHistoryTotalRow",
  ]),
};
</script>

<style scoped></style>
